import logo from './logo.svg';
import './App.css';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';

function App() {

  // Random component
const Completionist = () => <span>Prepare to be spooked</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div><h1>SPOOKY 420</h1>
        {days} DAYS<br/>
    <span>{hours} hours {minutes} minutes and {seconds} seconds</span></div>
      );
  }
};

  return (
    <div className="App">
      <header className="App-header">
  <Countdown date={new Date(2021, 10, 31)} renderer={renderer}/>
      </header>
    </div>
  );
}

export default App;
